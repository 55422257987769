import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed solid icons
library.add(far) // Include needed regular icons

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    theme: {
      defaultTheme: 'dark',

    },
  }, icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
});

app.use(vuetify).use(router)

app.mount('#app')
document.title = 'DAAC-Hermes TV planer::: by Sergiu Panainte'