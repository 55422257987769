<template>
  <v-footer class="text-center d-flex flex-column">
    <div class="pt-0 h-4">(C) DAAC-Hermes S.A.</div>

    <v-divider></v-divider>

    <div>{{ new Date().getFullYear() }} — <strong>Developer:Sergiu Panainte</strong></div>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>
