import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/test1',
  //   name: 'test1',

  //   component: () => import('../views/AboutView.vue')
  // },
  {
    path: '/service/:pdr/:keyopen',
    name: 'service',
    component: () => import('../views/test2.vue')
  },
  // {
  //   path: '/test0',
  //   name: 'test0',
  //   component: () => import('../views/TestZero.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
