<template>
  <v-app :dark="isDark">
    <v-main>
      <router-view />
    </v-main>

    <nav class="text-right">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/test0">Test 0</router-link>|
      <router-link to="/test1">Test 1</router-link> |
      <router-link to="/test2">Test 2</router-link>|<br />
      <router-link to="/service/petricani">petricani</router-link><br />
      <router-link to="/service/skoda">skoda</router-link><br />
      <router-link to="/service/jlr">jlr</router-link><br />
      <router-link to="/service/mazda">mazda</router-link><br />
      <router-link to="/service/cantemir">cantemir</router-link><br />
      <router-link to="/service/balti">balti</router-link><br /> -->
      <v-btn
        :icon="
          theme.global.name.value === 'light' ? 'fa-solid fa-moon' : 'fa-solid fa-sun'
        "
        slim
        @click="toggleTheme"
      ></v-btn>
    </nav>
    <FooterComp></FooterComp>
  </v-app>
</template>

<style>
#app {
  font-family: "volvo_broad_pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(143, 142, 142);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}
.v-overlay__content {
  width: 100%;
  height: 100%;
}
</style>
<script setup>
import { useTheme } from "vuetify";

const theme = useTheme();

function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}
function autoToggleTheme() {
  let htime = new Date().getHours();
  if (htime >= 10 && htime <= 16) {
    theme.global.name.value = "light";
  } else {
    theme.global.name.value = "dark";
  }
}

setInterval(() => {
  //autoToggleTheme();
}, 10000);
autoToggleTheme();
</script>

<script>
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "HomeView",

  components: {
    FooterComp,
  },

  data() {
    return {
      isDark: "",
    };
  },
  methods: {},
};
</script>
